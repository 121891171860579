// 请求相关
import {$msg_e, $toUrl} from './msg';
//导入 nprogress
import NProgress from 'nprogress'
//导入 nprogress的样式
import 'nprogress/nprogress.css'

import router from '@/router/index.js'

import { ElMessageBox, ElMessage } from 'element-plus'

// 导入axios
import axios from "axios";

// 导入接口根地址
import {BASE_URL} from '../config/conster.js'


// const BASE_URL = '/api'
// 初始化一个axios对象
var instance = axios.create({
  //设置请求地址前缀
  baseURL: BASE_URL,
  //请求超时时间
  timeout: 30000,
  //自定义请求头
  headers: {'token': localStorage.getItem('token')?localStorage.getItem('token'):''}
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    NProgress.start();
	  const login_token = localStorage.getItem('token');
	  if(login_token){
		  config.headers.token = login_token;
	  }
	  // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    NProgress.done();
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
	NProgress.done();
	const res = response.data
	// if the custom code is not 20000, it is judged as an error.
	if (res.code !== 200 && res.code !== 4040 ) {
	  ElMessage({
	    message: res.message || 'Error',
	    type: 'error',
	    duration: 5 * 1000
	  })

	  // 50001: token错误; 50002: Token过期;
	  if (res.code === 50001 || res.code === 50002) {
	    // to re-login
		  ElMessageBox.alert(
	      '登录状态失效，请重新登录',
	      '系统提示', {
	        confirmButtonText: '重新登录',
			showClose:false,
			type: 'warning'
	      }
	    ).then(() => {
	     	//清除storage
			  localStorage.removeItem('token');
			return router.push({ path: '/login'})
			// return Promise.reject();
			// router.push({path:'/login'});
		})
	  }

	  return response;
	  // return Promise.reject(new Error(res.message || 'Error'))
	} else {
		//登录成功
		if(res.login && res.data.token){
			console.log('login');
			localStorage.setItem('token',res.token);
		}
		return response;
	}

    // 对响应数据做点什么
  },
  function (error) {
    NProgress.done();
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      // 请求超时提示
      $msg_e('请求超时，请检查网络连接或稍后重试');
      window.location.reload();
    } else {
      // 对响应错误做点什么s
      return Promise.reject(error);
    }
  }
);

// 定义一个get请求方法
export let $get = async (url, params) => {
  let { data } = await instance.get(url, { params })
  return data
}

// 定义一个post请求方法
export let $post = async (url, params) => {
  let { data } = await instance.post(url, params)
  return data
}

// 设置token的方法，该方法，会将浏览器缓存中的token信息，添加到请求头
export let $setToken = () => {
  //将token信息，保存到请求头中
  instance.defaults.headers.common['token'] = localStorage.getItem('token');
}
