/* eslint-disable no-undef */
// 开发模式
let model = {
  //开发阶段接口地址
  dev: config.api,
  //测试阶段接口地址
  test: config.api,
  //发布阶段接口地址
  pro: config.api,
};

//定义请求根路径
export const BASE_URL = model.dev; //根据当前的开发阶段，返回对应的接口地址
