// 导入请求函数
import { $post, $get } from '../utils/request.js'

// 获取网页收集列表
export let webList = async (params) => {
    let res = await $post('openApi/Crawling/crawlingPage', params)
    return res
}

//网页收集转换内容
export let transFrom = async (params) => {
    let res = await $post('openApi/Gather/queryWeb', params)
    return res
}

//网页收集列表删除
export let del = async (params) => {
    let res = await $post('openApi/Crawling/crawlingDel', params)
    return res
}
//网页收集详情
export let details = async (params) => {
    let res = await $get('openApi/Crawling/getQueryDetail', params)
    return res
}
//网页重新采集
export let again = async (params) => {
    let res = await $post('openApi/Crawling/reQuery', params)
    return res
}
//收集配置列表
export let configList = async (params) => {
    let res = await $post('openApi/QueryList/page', params)
    return res
}
//收集配置删除
export let configListDel = async (params) => {
    let res = await $post('openApi/QueryList/del', params)
    return res
}
//收集配置新增或者编辑
export let configListAdd = async (params) => {
    let res = await $post('openApi/QueryList/save', params)
    return res
}

export let toAddQuery = async (params) => {
    let res = await $post('/openApi/Cron/addQuery', params)
    return res
}


//商品新增/修改
export let shopSave = async (params) => {
    let res = await $post('/openApi/Shop/save', params)
    return res
}

//商品详情
export let shopDetail = async (params) => {
    let res = await $post('/openApi/Shop/detail', params)
    return res
}


//商品列表
export let shopPage = async (params) => {
    let res = await $post('/openApi/Shop/page', params)
    return res
}
//商品删除
export let shopDel = async (params) => {
    let res = await $post('/openApi/Shop/delete', params)
    return res
}

/**
 * 后台登录
 * @param params
 * @returns {Promise<any>}
 */
export let adminLogin  = async (params) => {
    let res = await $post('/openApi/Login/login', params)
    return res;
}

/**
 * 智能采集列表分页
 * @param params
 * @returns {Promise<any>}
 */
export let aiQueryList  = async (params) => {
    let res = await $post('/openApi/AiQueryData/list', params)
    return res;
}

/**
 * 加载域名筛选列表
 * @param params
 * @returns {Promise<any>}
 */
export let aiQueryDomainList  = async (params=[]) => {
    let res = await $post('/openApi/AiQueryData/aiQueryDomainList', params)
    return res;
}

/**
 * 获取智能采集配置项
 * @param params
 * @returns {Promise<any>}
 */
export let getAiQueryConfig  = async (params=[]) => {
    let res = await $post('/admin/Config/getAiQueryConfig', params)
    return res;
}

/**
 * 保存配置项
 * @param params
 * @returns {Promise<any>}
 */
export let saveConfig  = async (params=[]) => {
    let res = await $post('/admin/Config/saveConfig', params)
    return res;
}

/**
 * 域名一键采集
 * @param params
 * @returns {Promise<any>}
 */
export let aiQuery  = async (params=[]) => {
    let res = await $post('/openApi/AiQuery/aiQuery', params)
    return res;
}

/**
 * 停止删除一键采集
 * @param params
 * @returns {Promise<any>}
 */
export let stopAiQuery  = async (params=[]) => {
    let res = await $post('/openApi/AiQuery/stopAiQuery', params)
    return res;
}

/**
 * 重置修改密码
 * @param params
 * @returns {Promise<any>}
 */
export let resetPwd  = async (params=[]) => {
    let res = await $post('/admin/User/resetPwd', params)
    return res;
}